body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-notification-notice-content .ant-notification-notice-message {
  margin-bottom: 0;
}

.ant-input-affix-wrapper .ant-input-prefix {
  margin-right: 1em;
}

button.ant-btn-ghost:hover, button.ant-btn-ghost:focus {
  color: black;
}